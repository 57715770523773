import { ReactNode } from 'react'
import { Label, labelVariants } from '@/src/components/ui/label'
import { cn } from '@/src/lib/utils'
import type { VariantProps } from 'class-variance-authority'

const ANIMATE_ERROR_MESSAGE_STYLES = 'transition-all ease-in-out duration-500 max-h-0 mb-0 opacity-0 text-cs-red'
const ANIMATE_VISIBLE_MESSAGE_STYLES = 'max-h-[48px] opacity-100'

export interface ErrorMessageProps extends VariantProps<typeof labelVariants> {
  children: ReactNode
  className?: string
  isVisible: boolean | undefined | null
  animate?: boolean
}

export default function ErrorMessage({ children, className, isVisible, animate, ...props }: ErrorMessageProps) {
  if (!animate && !isVisible) return null

  return (
    <Label
      className={cn(
        'text-cs-red mb-2 text-[9px]',
        animate && ANIMATE_ERROR_MESSAGE_STYLES,
        isVisible && animate && ANIMATE_VISIBLE_MESSAGE_STYLES,
        className
      )}
      {...props}
    >
      {children}
    </Label>
  )
}
