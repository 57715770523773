import { FormEvent, ReactNode, useState, useEffect } from 'react'
import Loader from '@/src/components/Loader'
import { AiOutlineGoogle, AiOutlineMail } from 'react-icons/ai'
import { useSession, signIn, type SessionContextValue, type SignInOptions } from 'next-auth/react'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import type { OAuthProviderType } from 'next-auth/providers/oauth-types'
import { Input } from '@/src/components/ui/input'
import isEmail from 'validator/lib/isEmail'
import { cn } from '@/src/lib/utils'
import * as React from 'react'
import Link from 'next/link'
import Separator from '@/src/components/Separator'
import ErrorMessage from '@/src/components/ErrorMessage'
import { Card, CardContent, CardHeader, CardTitle } from '@/src/components/Card'
// import { captureException } from '@sentry/nextjs'
import Spinner from '@/src/components/Spinner'

const logInLoadingStateTexts: Record<SessionContextValue['status'], string> = {
  unauthenticated: 'Start',
  loading: 'Requesting info',
  authenticated: 'Authenticated'
  // CREATING_SESSION: 'Creating auth-session',
}

const loginConfig: Partial<Record<OAuthProviderType, { icon: ReactNode; label: string }>> = {
  google: {
    icon: <AiOutlineGoogle size={22} />,
    label: 'Sign in with Google'
  }
}

const SocialButton = ({
  icon,
  label,
  onClick,
  isLoading
}: {
  icon: ReactNode
  label: string
  isLoading?: boolean
  onClick: (props?: any) => void
}) => (
  <button
    onClick={onClick}
    className={cn(
      'relative flex w-full items-center justify-start gap-3 rounded border-0 bg-white/10 px-6 py-2 hover:bg-white/[.15]',
      isLoading && 'opacity-50'
    )}
    type="submit"
  >
    {isLoading && <Spinner className="absolute left-[calc(50%-8px)] top-[calc(50%-8px)] mx-auto text-sm" />}
    <label className="w-5">{icon}</label>
    <span className="text-sm tracking-widest text-white">{label}</span>
  </button>
)

function LoginPage() {
  const searchParams = useSearchParams()
  const router = useRouter()
  const [showEmailSuccess, setShowEmailSuccess] = useState(false)
  const { data, status } = useSession()
  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<string>('')
  const [providerAuthenticating, setProviderAuthenticating] = useState<OAuthProviderType>()
  const redirectTo = searchParams.get('redirectTo') || '/'
  const sender = searchParams.get('sender')
  const user = data?.user

  // handle user going to /login when already logged in
  useEffect(() => {
    // if sender is internal, we got here from a protected route
    if (status === 'authenticated' && sender !== 'internal') router.push(redirectTo)
  }, [status])

  // TODO: if status === 'authenticated' && sender === 'internal', kick them to a /access-denied page or show an error message

  const handleLogin =
    (providerType: OAuthProviderType, opts: SignInOptions = {}) =>
    async (e: Event | FormEvent<HTMLFormElement>) => {
      e.preventDefault?.()

      if (providerType === 'email' && !isEmail(email)) {
        setEmailError('Invalid email')
        return
      }

      try {
        setProviderAuthenticating(providerType)
        await signIn(providerType, {
          email: providerType === 'email' ? email : undefined,
          callbackUrl: redirectTo,
          ...opts
        })
        if (providerType === 'email') setShowEmailSuccess(true)
      } catch (error) {
        // captureException(error)
        console.error(error)
      } finally {
        setProviderAuthenticating(undefined)
      }
    }

  return (
    <div className="relative flex h-screen min-h-screen w-full flex-col justify-normal overflow-hidden p-[72px] pt-[60px] lg:min-h-screen lg:flex-row lg:px-8 lg:pb-[calc(4rem+80px)] lg:pt-[82px]">
      <div className="flex h-full grow flex-col items-center justify-center overflow-hidden">
        {status !== 'loading' && !user && showEmailSuccess ? (
          <Card variant="glass" className="text-2xl">
            <CardHeader className="flex flex-row justify-center">
              <CardTitle>Check your email</CardTitle>
            </CardHeader>
            <CardContent>A sign in link has been sent to your email address.</CardContent>
          </Card>
        ) : (
          <div className="flex w-fit flex-col gap-2">
            {Object.entries(loginConfig).map(([key, { icon, label }]) => (
              <SocialButton
                isLoading={providerAuthenticating === key}
                key={key}
                icon={icon}
                label={label}
                onClick={handleLogin(key as OAuthProviderType)}
              />
            ))}
            <Separator className="-mx-8 text-sm" variant="light">
              OR
            </Separator>
            <form className="flex w-full flex-col" onSubmit={handleLogin('email')}>
              <ErrorMessage animate isVisible={!!emailError}>
                {emailError}
              </ErrorMessage>
              <div className="flex w-full flex-col gap-2">
                <Input value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter email..." />
                <SocialButton
                  isLoading={providerAuthenticating === 'email'}
                  icon={<AiOutlineMail size={22} />}
                  label="Sign in with email"
                  onClick={handleLogin('email', { redirect: false })}
                />
              </div>
            </form>
            <div className="flex flex-row justify-between pt-2 text-[11px]">
              <Link className="text-gray-400/40" href="https://triniti.plus/privacy-policy" target="_blank">
                Privacy Policy
              </Link>
              <Link className="text-gray-400/40" href="https://triniti.plus/terms-of-service" target="_blank">
                Terms & Conditions
              </Link>
            </div>
          </div>
        )}
        {status === 'loading' && <Loader text={logInLoadingStateTexts[status]} />}
      </div>
    </div>
  )
}

export default LoginPage
